import IndexPage from '../index';
import '@queries/index-page';
import { graphql } from 'gatsby';
export const query = graphql`
  query IndexPageQueryNB {
    ...IndexPage
  }
`;

const IndexPageNB = IndexPage;
export default IndexPageNB;

// import React, { useContext } from 'react';
// import { graphql } from 'gatsby';
// import GraphQLErrorList from '@components/graphql-error-list';
// import SEO from '@components/seo';
// import { Layout, LanguageContext } from '@layout/Layout';
// import { Quote } from '@components/Quote';

// import { Block } from '@components/Blocks';
// import { Ingress, Paragraph } from '@components/Text/Text';
// import BlockWidth from '@components/BlockWidth';
// import MainBlock from '@components/MainBlock';
// import ColWithHeading from '@components/ColWithHeading';
// import Card, { CardLarge } from '@components/Card/Card';
// import FlexedList from '@components/FlexedList/FlexedList';
// import localeString from '@lib/locale-string';
// import WithErrors from '@components/WithErrors';

// export const query = graphql`
//   query IndexPageQueryNo {
//     site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
//       title
//       description
//       keywords
//       slogan
//     }
//     index: sanityFrontPage(_id: { regex: "/(drafts.|)front-page/" }) {
//       title {
//         _key
//         _type
//         nb
//         en
//       }
//       ingress {
//         nb
//         en
//       }
//       topical {
//         label {
//           nb
//           en
//         }
//         articles {
//           __typename
//           ... on SanityCase {
//             slug {
//               current
//             }
//             localeTitle: title {
//               nb
//               en
//             }
//           }
//           ... on SanityCustomPage {
//             slug {
//               current
//             }
//             localeTitle: title {
//               nb
//               en
//             }
//           }
//           ... on SanityNewsletter {
//             slug {
//               current
//             }
//             title
//           }
//         }
//       }
//       services {
//         heading {
//           nb
//           en
//         }
//         articles {
//           title {
//             nb
//             en
//           }
//           image {
//             asset {
//               url
//             }
//           }
//           description {
//             nb
//             en
//           }
//           slug {
//             current
//           }
//         }
//       }
//       featuredCases {
//         reference {
//           id
//           title {
//             nb
//             en
//           }
//           image {
//             asset {
//               url
//             }
//           }
//           slug {
//             current
//           }
//           category {
//             category {
//               nb
//               en
//             }
//           }
//         }
//       }
//       quoteBy {
//         nb
//         en
//       }
//       quoteText {
//         nb
//         en
//       }
//     }
//   }
// `;

// const IndexPage = ({ data }) => {
//   const lang = useContext(LanguageContext);

//   const site = (data || {}).site;
//   const index = (data || {}).index;

//   const ingress = localeString(lang, index.ingress);
//   const { topical, services } = index;

//   const featuredCases =
//     index.featuredCases &&
//     index.featuredCases.map(({ reference: c }) => ({
//       id: c.id,
//       title: localeString(lang, c.title),
//       image: c.image,
//       category: localeString(lang, c?.category?.category, 'nb', 'Generelt'),
//       url: `/${lang}/case/${c.slug.current}`,
//     }));
//   const quoteText = index.quoteText.nb;
//   const quoteBy = index.quoteBy.nb;

//   if (!site) {
//     throw new Error(
//       'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
//     );
//   }

//   return (
//     <div className="main">
//       <SEO title={site.title} description={site.description} keywords={site.keywords} />
//       <BlockWidth align="right">
//         <MainBlock>
//           <Block>
//             <Ingress>{ingress}</Ingress>
//           </Block>
//           {topical && !!topical.articles.length && (
//             <Block type="s">
//               <FlexedList items={topical.articles}>
//                 <Paragraph>{topical.label.nb}:</Paragraph>
//               </FlexedList>
//             </Block>
//           )}
//         </MainBlock>
//       </BlockWidth>
//       {services && !!services.articles.length && (
//         <BlockWidth align="right">
//           <ColWithHeading heading={localeString(lang, services.heading)} col="3-x">
//             {services.articles.map(({ title, description, slug: { current }, ...props }) => (
//               <Card
//                 key={current}
//                 url={`/${lang}/page/${current}`}
//                 title={localeString(lang, title)}
//                 description={localeString(lang, description)}
//                 {...props}
//               />
//             ))}
//           </ColWithHeading>
//         </BlockWidth>
//       )}
//       <BlockWidth type="h">
//         <Quote quoteText={quoteText} quoteBy={quoteBy} />
//       </BlockWidth>
//       {featuredCases && featuredCases.length && (
//         <BlockWidth type="h" align="right">
//           <ColWithHeading align={'right'} col="2">
//             {featuredCases.map((props) => (
//               <CardLarge key={props.id} {...props} />
//             ))}
//           </ColWithHeading>
//         </BlockWidth>
//       )}
//     </div>
//   );
// };

// export default WithErrors(IndexPage);
